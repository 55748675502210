<template>
  <div @click="childClick" @mouseover="childMouseover" @mouseout="childMouseout">
    <span>{{title}}</span>
    <el-aside class="back" :style="{width: width ,height: height+'px'}">
      <div :class="status" :style="{width : getWidth() ,height: height-1.5+'px'}"></div>
    </el-aside>
  </div>
</template>

<script>

export default {
  name: "progress_bar",

  props:{
    title:{
      type:String,
      default: "",
    },
    total:{
      type:Number,
      default: 100,
    },
    value:{
      type:Number,
      default: 0,
      required: true
    },
    status:{
      type:String,
      default: "success"
    },
    num:{
      type:Number,
      default: 1
    },
    width:{
      type:String,
      default:"80px"
    },
    height:{
      type:Number,
      default:13
    }
  },
  methods:{
    getWidth(){
      if (this.total !== 0){
        if (this.status === "exception"){
          return "100%"
        }
        let per = this.value * 100 / this.total + "%"
        return per;
      }
      else {
        return "0%"
      }
    },
    childClick () {
      // childByValue是在父组件on监听的方法
      // 第二个参数this.childValue是需要传的值
      this.$emit('click',null)
    },
    childMouseover () {
      // childByValue是在父组件on监听的方法
      // 第二个参数this.childValue是需要传的值
      this.$emit('mouseover',null)
    },
    childMouseout () {
      // childByValue是在父组件on监听的方法
      // 第二个参数this.childValue是需要传的值
      this.$emit('mouseout',null)
    }
  }
}
</script>

<style scoped>
.back {
  background: #eeeeee ;
  border: 1px solid #c4c4c4;
  display: block;
}
.success {
  background: #67c23a ;
}
.warning {
  background: #e6a23c ;
}
.exception {
  background: #f56d6d;
}
.doing {
  background: #409eff ;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
</style>


