<template>
  <div>
    <div ref="parent" style="height: 40px">
      <div v-for="(item) in progresses" style="display: inline-block;margin-right: 3px">

        <el-popover
            :before-close="handleClose"
            placement="top"
            :title="item.gongxu"
            width="350"
            trigger="click"
            @close="datedialog = false">
          <el-card class="box-card2">
            <template slot="title">
              <el-tooltip placement="left" effect="light">
                <div slot="content">{{item.gongxu}}</div>
                <el-button type="text" size="small" STYLE="width: 120px">{{item.gongxu | ellipsis}}</el-button>
              </el-tooltip>
              <div style="display:flex;width: 350px">
                <div style="width: 80%" v-if='item.status == "exception"'><el-progress :percentage="item.pre" status="exception"></el-progress></div>
                <div style="width: 80%" v-if='item.status == "warning"'><el-progress :percentage="item.pre" status="warning"></el-progress></div>
                <div style="width: 80%" v-if='item.status == "success"'><el-progress :percentage="item.pre" status="success"></el-progress></div>
                <div style="width: 80%" v-if='item.status != "exception" && item.status != "warning" && item.status != "success"'><el-progress :percentage="item.pre"></el-progress></div>
              </div>
            </template>
            <div style="display: flex"></div>
            <div>

              <!-- 取自废数，料废不算 -->
              <div>废件数 :
                <el-button
                    type="text"
                    size="small"
                    @click = "errorProgress = item.gongxu,errorShow = true">
                  {{item.feijianNum}}
                </el-button></div>
<!--              <el-collapse>-->
<!--                <el-collapse-item style="width: 320px;">-->
<!--                  <template slot="title">-->
<!--                    <div style="display:flex;">废件信息 :-->
<!--                      <div v-for="(itemtitle,p) in item.feijianxinxi" style="display:flex">-->
<!--                        <div style="width: 50px;" >{{itemtitle.scrapLabels}}: {{itemtitle.num}}</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <div v-for="(itembody,q) in item.feiJianList">-->
<!--                    <el-descriptions >-->
<!--                      <el-descriptions-item label="废件标签">{{itembody.scrapLabels}}</el-descriptions-item>-->
<!--                      <el-descriptions-item label="废件数">{{itembody.scrapNumbers}}</el-descriptions-item>-->
<!--                      <el-descriptions-item label="人员" :span="2">{{itembody.yuangongName}}</el-descriptions-item>-->
<!--                      <el-descriptions-item label="备注">{{itembody.scrapNotes}}</el-descriptions-item>-->
<!--                    </el-descriptions>-->
<!--                    <el-divider></el-divider>-->
<!--                  </div>-->
<!--                </el-collapse-item>-->
<!--              </el-collapse>-->
<!--              <div>加工人 : <el-button type="text" size="small" @click = "renyuanshow(item.renyuanxinxi)">-->
<!--                {{item.renyuanList}}-->
<!--              </el-button></div>-->
              <div>目标加工数 : {{item.mubiaoNum}}</div>
              <div>已加工数 : {{item.workedNum}}</div></div>
            <div>
              <div style="display: flex">
                <div style="margin-top: 30px">废品率 :</div>
<!--                <el-progress type="dashboard" :percentage="item.feijianPre" :color="colors"></el-progress>-->
                <el-progress type="dashboard" :percentage="item.zifeiPre" :color="colors"></el-progress>
              </div>
            </div>
            <div>
              <div>
                <div>预计完工日期 :</div>
                <el-date-picker
                    v-model="finishDate"
                    v-if="item.finishDate || datedialog"
                    type="date"
                    :disabled="!datedialog"
                    placeholder="选择日期">
                </el-date-picker>
                <span slot="footer" class="dialog-footer" v-if="datedialog">
                  <el-button type="primary" @click="updateFinishDate(gongjianId,item.gongxu,finishDate)">确 定</el-button>
                </span>
                <el-button type="text" v-if="!datedialog" @click="datedialog = true">编辑</el-button>
                <el-button type="text" v-if="datedialog" @click="datedialog = false">取消</el-button>
              </div>
              <!--                      <div>预计完工日期 : {{item.gongxu}}</div>-->
            </div>
          </el-card>
          <progress-bar
              :title="item.gongxu"
              :value="item.workedNum + item.feijianNum"
              :total="item.mubiaoNum"
              :status="item.status"
              :width="width"
              slot="reference"
              @click="details = item,finishDate = item.finishDate"/>
        </el-popover>
      </div>
    </div>
    <el-dialog
        title="废件详情"
        :visible.sync="errorShow"
        width="30%">
      <error-products :id="gongjianId" :progress="errorProgress" v-if="errorShow"/>
    </el-dialog>

<!--    <el-dialog-->
<!--        title="设置最后日期"-->
<!--        :visible.sync="datedialog"-->
<!--        width="30%">-->
<!--      <el-date-picker-->
<!--          v-model="finishDate"-->
<!--          type="date"-->
<!--          placeholder="选择日期">-->
<!--      </el-date-picker>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="updateFinishDate()">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import progressBar from "./progress_bar"
import request from "@/utils/request";
import errorProducts from "@/views/element/error_products_list";

export default {
  name: "progress_bar_group",
  components: { progressBar , errorProducts },

  props:{
    progresses:{
      type:Array,
      default: () => [],
      required: true
    },
    gongjianId:{
      type:Number,
      required: true
    }
  },
  data() {
    return {
      width:"80px",
      details:{},
      colors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6be3c', percentage: 40},
        {color: '#cdff4e', percentage: 60},
        {color: '#5cb87a', percentage: 80},
      ],
      datedialog: false,
      finishDate: null,
      finishGongjina: null,
      finishGongxu: null,
      errorProgress:"",
      errorShow:false,
    }
  },
  mounted() {
    this.width = this.getWidth()
  },
  filters:{
    ellipsis(value){
      if (!value) return '';
      if (value.length > 7) {
        return value.slice(0,6) + '...'
      }
      return value
    }
  },
  methods:{
    getWidth(){
      try {
        let width = this.$refs.parent.offsetWidth;
        // let width = this.$parent.$el.offsetWidth;
        return (width - 10)/this.progresses.length - 3 + "px"
      }catch (e){
      }
      return "50px";
    },
    updateFinishDate(gongjian,gongxu,finishDate){
      this.datedialog = false;
      let item = {
        gongjian:gongjian,
        gongxu:gongxu,
        finishDate:finishDate,
      }
      request.post("/updateFinishDate",item).then(res => {
        if (res.code === '200') {
          this.select();
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        return null;
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
  }
}
</script>

<style scoped>
.success {
  background: #67c23a ;
}
.warning {
  background: #e6a23c ;
}
.exception {
  background: #f56d6d;
}
.doing {
  background: #409eff ;
}
.el-input.is-disabled /deep/ .el-input__inner {
  color: #606266;
}
</style>