<template>
  <el-table
      :data="scriapList"
      border>
    <el-table-column
        prop="noid"
        v-if="false"
        label="废件id">
    </el-table-column>
    <el-table-column
        prop="id"
        v-if="false"
        label="工件id">
    </el-table-column>
    <el-table-column
        prop="yuangongName"
        label="员工">
    </el-table-column>
    <el-table-column
        prop="scrapLabels"
        label="废件标签">
    </el-table-column>
    <el-table-column
        prop="scrapNumbers"
        label="废件数量">
    </el-table-column>
    <el-table-column
        prop="scrapNotes"
        label="备注">
    </el-table-column>
    <el-table-column
        fixed="right"
        prop="caozuo"
        label="操作">
    </el-table-column>
  </el-table>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "error_products_list",
  props:{
    id:{
      type:Number,
      required: true
    },
    progress:{
      type:String,
      default: ""
    },
  },
  data() {
    return {
      scriapList:[]
    }
  },
  mounted() {
    this.select()
  },
  methods:{
    select(){
      request.post("/NonConformingProductSelectOne",this.id).then(res => {
        if (res.code == "200"){
          if(this.progress === "" || this.progress === null || this.progress === undefined){
            this.scriapList = res.data
          } else {
            this.scriapList = res.data.filter(item=>(item.gongxu === this.progress))
          }
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>